<template>
  <div>
    <v-col cols="3">
      <v-btn
        block
        height="42px"
        color="#1db954"
        class="download-btn"
        @click="openModal('create')"
        style="color: black"
      >
        <v-icon>mdi mdi-plus-thick</v-icon>
        Neu
      </v-btn>
    </v-col>
    <v-data-table
      :key="insectUpdateKey"
      :headers="headers"
      :search="performSearch"
      :items="insects"
      :loading="isLoading"
      :footer-props="{
        'items-per-page-text': 'Zeilen pro Seite',
      }"
    >
      <template v-slot:item.actions="{ item }">
        <div class="button-container">
          <v-icon
            small
            class="edit green--text icon-style"
            @click="openModal('update', item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            class="delete green--text icon-style"
            @click="openDeleteModal('delete', item)"
          >
            mdi-delete
          </v-icon>
        </div>
      </template>
    </v-data-table>
    <create-insect
      :insect="{ abbreviation: '', germanName: '', latinName: '' }"
      :visible="dialogType === 'create'"
      :id="null"
      @close="closeModal"
      @addItem="addItem"
      v-on:updated-table="updatedList"
    />
    <update-insect
      :key="insectUpdateKey"
      :insect="selectedInsect"
      :visible="dialogType === 'update'"
      :id="selectedInsect.id"
      @close="closeModal"
      @updateItem="updateItem"
      v-on:updated-table="updatedList"
    />
    <delete-insect
      :visible="dialogType === 'delete'"
      :insect="selectedInsect"
      :item-list="itemList"
      v-on:updated-table="updatedList"
      @close="closeDeleteModal"
    ></delete-insect>
  </div>
</template>

<script>
import CreateInsect from "./CreateInsect";
import UpdateInsect from "./UpdateInsect";
import DeleteInsect from "./DeleteInsect";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    CreateInsect,
    UpdateInsect,
    DeleteInsect,
  },
  data() {
    return {
      headers: [
        { text: "Abkürzung", value: "abbreviation" },
        { text: "Latein", value: "latinName" },
        { text: "Deutsch", value: "germanName" },
        { text: "", value: "actions", sortable: false },
      ],
      itemList: [],
      insectUpdateKey: null,
      insectList: [],
      isLoading: false,
      dialogType: "",
      selectedInsect: {},
      localSearch: null,
      showDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters(["getSearchquery", "insects"]),

    performSearch() {
      return this.getSearchquery;
    },
  },
  mounted() {
    this.localSearch = this.getSearchquery;
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["fetchInsects", "createInsect", "updateInsect"]),
    editInsect(insect) {
      this.selectedInsect = { ...insect };
      this.dialogType = "update";
    },
    updatedList(boolean) {
      if (boolean) {
        this.fetchData();
      }
    },
    openModal(type, insect) {
      this.dialogType = type;
      if (type === "update") {
        this.selectedInsect = { ...insect };
      }
    },
    openDeleteModal(type, insect) {
      this.dialogType = type;
      if (type === "delete") {
        this.selectedInsect = { ...insect };
      }
    },
    closeModal() {
      this.dialogType = "";
      this.selectedInsect = {};
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.dialogType = "";
      this.selectedInsect = {};
    },
    async addItem(newItem) {
      this.isLoading = true;
      await this.createInsect(newItem);
      this.closeModal();
    },
    async updateItem(item) {
      this.isLoading = true;
      await this.UpdateInsect(item);
      this.insectUpdateKey += 1;
      this.closeModal();
    },
    fetchData() {
      this.isLoading = true;
      this.fetchInsects()
        .then(() => {
          this.isLoading = false;
          this.$store.commit("setInsectTypeResults", response.data);
          this.insects = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  handleSearch(query) {
    this.getSearchquery = query;
  },
};
</script>

<style lang="scss" scoped>
.icon-style {
  border: 1px solid #494949;
  padding: 3px;
  border-radius: 5px;
  width: 42px;
  height: 42px;
  margin-left: -60px !important;
}

.button-container {
  display: flex;
  align-items: center;
}
.button-container .delete {
  position: absolute;
  right: 15px;
}
.button-container .edit {
  position: absolute;
  right: 67px;
}
.add-button {
  outline: none !important;
  margin-bottom: 10px;
  width: 220px;
}
</style>
